const IS_NOT_DELETED = 'Ні'
const IS_DELETED = 'Так'

export const IS_PUSH_DELETED = Object.freeze([
  {
    id: 0,
    name: IS_NOT_DELETED,
    value: false,
  },
  {
    id: 1,
    name: IS_DELETED,
    value: true,
  },
])
