const IS_NOT_READED = 'Ні'
const IS_READED = 'Так'

export const IS_PUSH_READED = Object.freeze([
  {
    id: 0,
    name: IS_NOT_READED,
    value: 0,
  },
  {
    id: 1,
    name: IS_READED,
    value: 1,
  },
])
