<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="notifications"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getNotificationsCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>
              <span>Розсилка повідомлень id{{ deliveryId }}</span>
              <span v-if="settingsId">, правило id{{ settingsId }}</span>
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <div class="font-italic font-weight-medium">{{ getItemTitle(pushNotificationEventsDictionary, eventId) }}</div>
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{ item.created_at | getShortDate }}</div>
          <sup>{{ item.created_at | getShortTime }}</sup>
        </template>
        <template v-slot:item.receiver="{ item }">
          <router-link
            :to="{ name: 'ClientCard', params: { id: currentCompanyId, clientId: item.receiver.id } }"
            class="text-decoration-none"
          >
            {{ item.receiver.first_name }} {{ item.receiver.last_name }} (id{{ item.receiver.id }})
          </router-link>
        </template>
        <template v-slot:item.status="{ item }">
          {{ getItemTitle(IS_PUSH_READED, item.status) }}
        </template>
        <template v-slot:item.is_deleted_by_user="{ item }">
          {{ getItemTitle(IS_PUSH_DELETED, item.is_deleted_by_user) }}
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import convertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import convertPartnershipTypes from '@/mixins/convertPartnershipTypes'
import BackButton from '@/components/common/BackButton'
import sortUtils from '@/mixins/sortUtils'
import convertToDictionary from '@/mixins/convertToDictionary'
import { IS_PUSH_READED } from '@/const/isPushReaded.enum'
import { IS_PUSH_DELETED } from '@/const/isPushDeleted.enum'

export default {
  name: 'PushNotificationDelivery',
  components: {
    BackButton,
  },

  mixins: [convertPartnershipTypes, convertDate, resetTableData, sortUtils, convertToDictionary],

  data: () => ({
    IS_PUSH_READED: IS_PUSH_READED,
    IS_PUSH_DELETED: IS_PUSH_DELETED,
    search: '',
    loading: false,
    headers: [
      { text: 'Дата', value: 'created_at' },
      { text: 'Отримувач', value: 'receiver' },
      { text: 'Назва повідомлення', value: 'title' },
      { text: 'Текст повідомлення', value: 'body' },
      { text: 'Прочитано', value: 'status', align: 'center' },
      { text: 'Видалено отримувачем', value: 'is_deleted_by_user', align: 'center' },
    ],
    options: {},
  }),

  beforeDestroy() {
    this.clearDate()
  },

  computed: {
    ...mapState('pushNotification', ['notifications']),
    ...mapState('dictionaries', ['pushNotificationEventsDictionary']),
    ...mapGetters('pushNotification', ['getNotificationsCount']),

    currentCompanyId() {
      return this.$route.params.id
    },
    deliveryId() {
      return this.$route.params.deliveryId || ''
    },
    eventId() {
      return this.$route.query.event || ''
    },
    settingsId() {
      return this.$route.query.settings || ''
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('pushNotification', ['loadPushNotifications']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('pushNotification', ['SET_NOTIFICATIONS']),

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const payload = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          'execution.id': this.deliveryId,
        }
        await this.loadPushNotifications(payload)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    clearDate() {
      this.SET_NOTIFICATIONS([])
    },
  },
}
</script>
